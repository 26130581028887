import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
const SaldosCaja = Loadable(lazy(() => import('../views/saldos-caja/saldos-caja')))
const SaldosInventario = Loadable(lazy(() => import('../views/saldos-inventario/saldos-inventario')))
const GastosAgrupados = Loadable(lazy(() => import('../views/gastos-agrupados/gastos-agrupados')))
const VentasDiariasCr = Loadable(lazy(() => import('../views/ventas-diarias-cr/ventas-diarias-cr')))
const VentasDiariasI = Loadable(lazy(() => import('../views/ventas-diarias-i/ventas-diarias-i')))
const RecaudosDia = Loadable(lazy(() => import('../views/recaudos-dia/recaudos-dia')))
const RecaudosDiaInv = Loadable(lazy(() => import('../views/recaudos-dia-inv/recaudos-dia')))
const ClientesActivos = Loadable(lazy(() => import('../views/clientes-activos/clientes-activos')))
const CreditosNuevos = Loadable(lazy(() => import('../views/creditos-nuevos/creditos-nuevos')))
const CreditosRefinanciados = Loadable(lazy(() => import('../views/creditos-refinanciados/creditos-refinanciados')))
const CreditosRecogidos = Loadable(lazy(() => import('../views/creditos-recogidos/creditos-recogidos')))
const ErrorPage = Loadable(lazy(() => import('../views/authentication/Error')));
const RegisterPage = Loadable(lazy(() => import('../views/authentication/Register')));
const LoginPage = Loadable(lazy(() => import('../views/authentication/Login')));

// Función auxiliar para verificar si el usuario ha iniciado sesión
const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  return token !== null;
};

const PrivateRoute = ({ path, element: Element }) => {
  return isAuthenticated() ? (
    <Element />
  ) : (
    <Navigate to="/auth/login" replace />
  );
};

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<FullLayout />}>
        <Route index element={<Navigate to="/saldos-caja" replace />} />
        <Route path="/saldos-caja" element={<PrivateRoute element={SaldosCaja} />} />
        <Route path="/saldos-inventario" element={<PrivateRoute element={SaldosInventario} />} />
        <Route path="/giros" element={<PrivateRoute element={GastosAgrupados} />} />
        <Route path="/venta-diaria" element={<PrivateRoute element={VentasDiariasCr} />} />
        <Route path="/venta-diaria-i" element={<PrivateRoute element={VentasDiariasI} />} />
        <Route path="/recaudos-dia" element={<PrivateRoute element={RecaudosDia} />} />
        <Route path="/recaudos-dia-inv" element={<PrivateRoute element={RecaudosDiaInv} />} />
        <Route path="/clientes-activos" element={<PrivateRoute element={ClientesActivos} />} />
        <Route path="/creditos-nuevos" element={<PrivateRoute element={CreditosNuevos} />} />
        <Route path="/creditos-refinanciados" element={<PrivateRoute element={CreditosRefinanciados} />} />
        <Route path="/creditos-recogidos" element={<PrivateRoute element={CreditosRecogidos} />} />
        <Route path="/auth/404" element={<ErrorPage />} />
        <Route path="*" element={<Navigate to="/auth/404" replace />} />
      </Route>
      <Route path="/auth" element={<BlankLayout />}>
        <Route path="/auth/register" element={<RegisterPage />} />
        <Route
          path="/auth/login"
          element={
            isAuthenticated() ? (
              <Navigate to="/" replace />
            ) : (
              <LoginPage onLogin={() => {}} />
            )
          }
        />
        <Route path="*" element={<Navigate to="/auth/404" replace />} />
      </Route>
    </Routes>
  );
};

export default Router;
