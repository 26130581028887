import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import Router from './routes/Router';
import { baselightTheme } from "./theme/DefaultColors";
import '../src/assets/styles/global-styles.css';

function App() {
  const theme = baselightTheme;
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
}

export default App;
